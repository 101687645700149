<template>
    <div>
        <teamList :game_id="game_id"></teamList>
    </div>
</template>

<script>

import teamList from '@/components/games/team/list.vue';

export default {
    components: {
        teamList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 3,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>