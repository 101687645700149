<template>
    <div>
        <Drawer
            v-model="drawer_edit"
            width="500"
            :mask-closable="true"
        >
            <teamEdit :game_id="game_id" :team_id="edit_id" @closePage="closeEditDrawer"></teamEdit>
        </Drawer>  

        <Row style="margin-bottom:10px;">
            <Col span="4" style="text-align: left">
                <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
            </Col>
            <!-- <Col style="text-align: right">
                <Checkbox v-model="deleted" border>筛选删除</Checkbox> 
            </Col> -->
            <Col >
                <Button type="success"  @click="add">新增</Button>
            </Col>
        </Row>
        <fitTable 
            :columns="columns" 
            :data="data" 
            :loading="loading" 
        >
        </fitTable>
        <Page
            :total="total_page"
            :current="current_page"
            :page-size="page_size"
            @on-change="onPageChange"
            show-total
            show-elevator
        ></Page>
    </div>
</template>
<script>

import { getTeams } from '@/api/games/team';
import moment from 'moment';
import {getImgGameUrl} from '@/utils/util';
import fitTable from '../../basic/table.vue';
import teamEdit from './edit.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id);
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                // type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

const lineupButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'warning',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { team_id: params.row.id, game_id: params.row.game_id }
                    let sport = vm.$conf.games.games[vm.game_id];
                    if (sport){
                        let path = '/cmsv2/esports/team/lineup';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的游戏")
                    }
                }
            }
        },
        '阵容'
    )
}

export default {
    name: "team-list",
    props: {
        game_id: Number,   // 运动id
    },
    components: {
        teamEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            deleted: false,
            drawer_edit: false,
            edit_id : 0,

            search_key: '',

            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: '中文名称',
                    minWidth: 80,
                    align: 'center',
                    key: 'name_zh',
                    game_ids: [1,2,6],
                },
                {
                    title: '英文名称',
                    minWidth: 80,
                    align: 'center',
                    key: 'name_en',
                },
                {
                    title: '中文简称',
                    minWidth: 80,
                    align: 'center',
                    key: 'abbr_zh',
                    game_ids: [1,2,6],
                },
                {
                    title: '英文简称',
                    minWidth: 80,
                    align: 'center',
                    key: 'abbr_en',
                },
                {
                    title: 'logo',
                    key: 'logo',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let logo = currentRow.logo;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                            [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'team', logo), shape: 'square', size: 'middle' },
                                class: {'team-logo': true} })],
                        ));
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '创建时间',
                    width: 100,
                    align: 'center',
                    key: 'create_time',
                    game_ids: [1,2,3],
                    render: function (h, params) {
                        let currentRow = params.row;
                        if (currentRow.create_time){
                            return h( 'span', moment.unix(currentRow.create_time).format('YYYY-MM-DD HH:mm:ss') );
                        }else{
                            return h( 'span', '' );
                        }
                    }
                },
                {
                    title: '国家',
                    key: 'country',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let country = currentRow.country;
                        let resp = [];
                        if (country.id > 0){
                            resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                [h('img', { attrs: { src: getImgGameUrl(self.game_id, 'country', country.logo), shape: 'square', size: 'middle' },
                                    class: {'country-logo': true} })],
                            ));
                            resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                [
                                    h('span', {class: 'text-link', on: {
                                        'click':() => {
                                            // self.editTeam(home.id);
                                        }
                                    } },  country.id + ' ',  ),
                                    h('span', {class: 'text-main',on: {
                                        'click':() => {
                                            // self.editTeam(home.id);
                                        }
                                    } }, country.name,  )
                                ],
                            ))
                        }
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '性别',
                    width: 100,
                    align: 'center',
                    key: 'create_time',
                    game_ids: [2],
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', currentRow.gender==1?'男': ( currentRow.gender==2? '女': '' ) );
                    }
                },
                // {
                //     title: '被删除',
                //     key: 'deleted',
                //     align: 'center',
                //     minWidth: 80,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let deleted = currentRow.deleted;
                //         let deleted_str = deleted == 0 ? '正常': (
                //             deleted == 1 ? '被删除': '未知:'+deleted
                //         )
                //         const deleted_status = deleted == 0 ? 'success': (
                //             deleted == 1 ? 'error': 'default:'
                //         )
                //         return [h( 'Badge', {attrs: {status: deleted_status,  }} ), 
                //                 h('span', deleted_str)]
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 150,
                    render (h, params) {
                        let btns = [editButton(self, h, params), ]
                        if (self.game_id === 1){
                            btns.push(lineupButton(self, h, params));
                        }
                        return btns;  // delButton(self, h, params)
                    }
                }
            ],
            data: [],
            drawer_styles: {
                padding: '2px',
            },
            // 编辑
        }
    },
    methods: {
        onPageChange (page) {
            // console.log("1 change", page);
            this.current_page = page;
            this.getData()
        },
        onSearch (search_key) {
            // console.log("2 change", search_key);
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData (status_ids) {
            let self = this;
            this.loading = true
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // 搜索状态
            if (status_ids){
            }else{
                if (this.status_ids){
                    // console.log(this.status_ids, typeof(this.status_ids) );
                    var status_ids = this.status_ids.join(',');
                }else{
                    var status_ids = '';
                }
            }
            //
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            var params = {
                game_id: this.game_id,
                search_key: this.search_key,
                page: this.current_page,
                lang: lang,
            }
            if (self.deleted){
                params['deleted'] = 1;
            }
            getTeams(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list
                    this.total_page = response.data.data.total_page
                    this.page_size = response.data.data.page_size
                    this.current_page = response.data.data.current_page
                    this.loading = false
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        
        
        add () {
            this.edit_id = 0;
            this.drawer_edit = true
        },
        delete (_id) {
            var params = {
                id: _id,
                type: this.data_type
            }
            // console.log(params);
            deleteData(params).then(response => {
                if (response.data.code == 0) {
                    return true
                } else {
                    this.$Message.error(response.data.msg)
                    return false
                }
            })
        },
        // end 

    },
    mounted () {
        this.$nextTick(function () {
            // 只有网球展示列: 盘数
            this.columns = this.columns.filter(col => {
                if (col.game_ids && col.game_ids.length > 0){
                    return col.game_ids.indexOf( this.game_id ) > -1 ;
                } else{
                    return true;
                }
            });
            this.getData();
        })
    },
    watch: {
        // status_ids (value){
        // },
        deleted(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css";
</style>